import { applyMiddleware, combineReducers, createStore } from 'redux';
import { connectRoutes } from 'redux-first-router';
import qs from 'qs';
import { composeWithDevTools } from 'redux-devtools-extension';
import { page, offer, error, cmsPage, trip, dailyDeals, auth } from './reducers';
import { router } from 'infrastructure/services';
import { effectsMiddleware } from 'infrastructure/middlewares';

export const configureStore = (path: string, preloadedState?: any) => {
  const { reducer, middleware, enhancer, thunk } = connectRoutes(router.mappedRoutes, {
    initialEntries: [
      {
        pathname: path,
      },
    ],
    querySerializer: qs,
  });

  const rootReducer = combineReducers({
    component: page,
    location: reducer,
    offers: offer,
    error,
    cmsPage,
    trip,
    dailyDeals,
    auth,
  });

  const middlewares = applyMiddleware(middleware, effectsMiddleware);
  const enhancers = composeWithDevTools(enhancer, middlewares);
  const store = createStore(rootReducer, preloadedState, enhancers);

  return { store, thunk };
};
