export enum RouteName {
  HOME = 'HOME',
  OFFER = 'OFFER',
  ABOUT = 'ABOUT',
  FAQ = 'FAQ',
  CONTACT = 'CONTACT',
  TERMS = 'TERMS',
  POLICY = 'POLICY',
  MAP = 'MAP',
  LISTING = 'LISTING',
  SEARCH = 'SEARCH',
  CHECKBOX = 'CHECKBOX',
  SEARCH_RESULTS = 'SEARCH_RESULTS',
  BOX_OFFERS = 'BOX_OFFERS',
  ERROR_404 = 'ERROR_404',
  CMS_PAGE = 'CMS_PAGE',
  MY_TRIP = 'MY_TRIP',
  BESTSELLERS = 'BESTSELLERS',
  LOG_IN = 'LOG_IN',
}
