import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Urgency } from 'types/interfaces/urgency';
import { UrgencyThresholds, UrgencyTypes } from './urgencyUtils';

export const getUrgencies = (urgencies: Urgency): JSX.Element[] => {
  let data: JSX.Element[] = [];
  // Best seller
  if (urgencies.topTenRanked) {
    data.push(<FormattedMessage id={UrgencyTypes.topTenRanked} />);
  }
  // Bookings
  if (
    urgencies.salesflowViewsLastHalfHour >
    UrgencyThresholds[UrgencyTypes.salesflowViewsLastHalfHour]!
  ) {
    data.push(
      <FormattedMessage
        id={UrgencyTypes.salesflowViewsLastHalfHour}
        values={{ value: <strong>{urgencies.salesflowViewsLastHalfHour}</strong> }}
      />
    );
  } else if (
    urgencies.salesflowViewsLastHalfHour ===
    UrgencyThresholds[UrgencyTypes.salesflowViewsLastHalfHour]!
  ) {
    // tbc
  } else if (urgencies.bookedToday) {
    data.push(<FormattedMessage id={UrgencyTypes.bookedToday} />);
  }
  // Views
  if (urgencies.viewsLastHour > UrgencyThresholds[UrgencyTypes.viewsLastHour]!) {
    // TODO: plurals
    data.push(
      <FormattedMessage
        id={UrgencyTypes.viewsLastHour}
        values={{ value: <strong>{urgencies.viewsLastHour}</strong> }}
      />
    );
  } else if (urgencies.viewsLast24Hours > UrgencyThresholds[UrgencyTypes.viewsLast24Hours]!) {
    data.push(
      <FormattedMessage
        id="VIEWS_LAST_24_HOURS"
        values={{ value: <strong>{urgencies.viewsLast24Hours}</strong> }}
      />
    );
  }
  // Discount
  if (urgencies.priceDecreasedInLast24Hours) {
    data.push(<FormattedMessage id={UrgencyTypes.priceDecreasedInLast24Hours} />);
  }
  return data;
};
