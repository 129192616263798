import React from 'react';
import { connect } from 'react-redux';
import { actionFactory } from 'infrastructure/services';
import { HandleError } from 'infrastructure/actions';
import { ErrorContent } from './ErrorContent';
import { default as ErrorModal } from './ErrorModal';
import { ErrorHandlerType } from 'types/enums';

interface ErrorBoundaryProps {
  setError: (status: boolean, type: ErrorHandlerType) => void;
  error: {
    hasError: boolean;
    type: string | null;
  };
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { setError } = this.props;
    setError(true, ErrorHandlerType.STATIC);
    console.log(error, errorInfo);
  }

  render() {
    const { hasError, type } = this.props.error;
    if (!hasError) {
      return this.props.children;
    }
    if (type === ErrorHandlerType.STATIC) {
      return <ErrorContent />;
    }
    return (
      <>
        <ErrorModal />
        {this.props.children}
      </>
    );
  }
}

export default connect(
  (state: any) => ({
    error: state.error,
  }),
  dispatch => ({
    setError: (hasError: boolean, type: ErrorHandlerType) =>
      dispatch(
        actionFactory.create(HandleError, {
          hasError,
          type,
        })
      ),
  })
)(ErrorBoundary);
