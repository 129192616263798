import { lazy } from 'react-imported-component';

const Error404 = lazy(() => import('./404'));
const AboutUs = lazy(() => import('./AboutUs'));
const Contact = lazy(() => import('./Contact'));
const Terms = lazy(() => import('./Terms'));
const FAQ = lazy(() => import('./FAQ'));
const Policy = lazy(() => import('./Policy'));
const SiteMap = lazy(() => import('./SiteMap'));

export { Error404, AboutUs, Contact, Terms, FAQ, Policy, SiteMap };
