import { Action } from './Action';
import { ActionType } from 'types/enums';

export class UserFetched extends Action {
  readonly type: string = ActionType.USER_FETCHED;

  constructor(payload: string) {
    super(payload);
    this.payload = payload;
  }
}
