import gql from 'graphql-tag';

export const GET_DAILY_DEALS = gql`
  query getDailyDeals {
    dailydeals {
      id
      shortTitle
      price
      relativeUrl
      currency
      ratings {
        count
        average
      }
      image {
        url(w: 1138, dpr: 72)
      }
      offerCard {
        mainLocation
        discountPercentage
        priceTitle
      }
    }
  }
`;
