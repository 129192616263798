import { Action } from './Action';
import { ActionType } from 'types/enums';
import { apolloClient } from '../apollo/client';
import { GET_OFFERS } from '../graphql';
import { actionFactory } from '../services';
import { OffersFilters } from './OffersFilters';

export class OffersFetched extends Action {
  readonly type: string = ActionType.OFFERS_FETCHED;
}

export class OffersFetch extends Action {
  readonly type: string = ActionType.OFFERS_FETCH;

  constructor(payload: string) {
    super(payload);
    this.payload = payload;
  }

  async effects(dispatch?: any) {
    const filters = this.payload;
    apolloClient
      .query({
        query: GET_OFFERS,
        fetchPolicy: 'no-cache',
        variables: filters,
      })
      .then(response => {
        dispatch(actionFactory?.create(OffersFilters, { filters: filters, pushToUrl: false }));
        dispatch(actionFactory?.create(OffersFetched, response.data));
      });
  }
}
