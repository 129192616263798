import { OfferList } from 'types/interfaces';
import { ActionType } from 'types/enums';

interface defaultState {
  list: OfferList;
  listLoading: boolean;
}

const defaultState: defaultState = {
  list: [],
  listLoading: true,
};

export const dailyDeals = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.DAILY_DEALS_LOADING:
      return {
        ...state,
        listLoading: action.payload,
      };
    case ActionType.DAILY_DEALS_FETCHED:
      const list = [...state.list, ...action.payload.dailydeals];
      return {
        ...state,
        list: list,
        listLoading: false,
      };
    default:
      return state;
  }
};
