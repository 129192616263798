import { Action } from './Action';
import { ActionType } from 'types/enums';
import { apolloClient } from 'infrastructure/apollo/client';
import { GET_CURRENT_USER } from 'infrastructure/graphql';
import { actionFactory } from 'infrastructure/services';
import { UserFetched } from './UserFetched';

export class UserFetch extends Action {
  readonly type: string = ActionType.USER_FETCH;

  async effects(dispatch?: any) {
    apolloClient
      .query({
        query: GET_CURRENT_USER,
        fetchPolicy: 'no-cache',
      })
      .then(response => {
        dispatch(actionFactory.create(UserFetched, response.data.currentUser));
      })
      .catch(() => {
        dispatch(actionFactory.create(UserFetched, null));
      });
  }
}
