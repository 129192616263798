import gql from 'graphql-tag';

export const GET_TRIP = gql`
  query getMyTrip($token: ID!) {
    trip(token: $token) {
      participantsTitle
      title
      hasFlights
      category
      needsPayment
      paymentInProgress
      paymentDueDate
      arrivalDate
      departureDate
      hasTripguide
      nights
      duration
      referenceId
      mainLocation
      accomodations {
        name
        city
        contactEmail
        contactPhone
        country
        countryCode
        description
        duration
        formattedAddress
        latitude
        longitude
        nights
        checkinDate
        checkoutDate
        checkinFrom
        checkingUntil
        checkoutFrom
        checkoutUntil
        facilities {
          icon
          nameTranslated
          name
        }
        rooms {
          id
          content
          image
          facilities {
            icon
            nameTranslated
            name
          }
        }
        includedServices {
          nameTranslated
          name
        }
      }
      participants {
        firstName
        lastName
        gender
        birthDate
        email
        phone
        mobilePhone
        zipCode
        streetNumber
        city
        country
      }
      payment {
        deferred
        shouldListInstalments
        orderItems {
          price
          title
        }
        excludedPaymentItems {
          price
          title
        }
        extraInformations {
          text
          title
        }
      }
    }
  }
`;
