import {
  Home,
  Offer,
  AboutUs,
  Policy,
  SiteMap,
  Terms,
  Contact,
  FAQ,
  Search,
  Channel,
  CMSpage,
  MyTrip,
  Bestsellers,
} from 'pages';
import { Route } from 'types/interfaces';
import { RouteName } from 'types/enums';
import { apolloClient } from 'infrastructure/apollo/client';
import { SectionSearchResults } from 'components';
import {
  OffersCleared,
  OffersFetched,
  OfferFetched,
  TripFetched,
  HandleError,
  DailyDealsFetched,
} from 'infrastructure/actions';
import {
  GET_OFFER,
  GET_OFFERS,
  GET_MOCK_OFFERS,
  GET_TRIP,
  GET_DAILY_DEALS,
} from 'infrastructure/graphql';

import { actionFactory } from 'infrastructure/services';
import { useMocks, validateFilters } from 'infrastructure/utils';
import { ErrorHandlerType } from 'types/enums';
import { CMSPageFetch } from './actions/CMSpageFetched';
import { OffersFilters } from './actions/OffersFilters';

const errorHandler = (dispatch: any) => () =>
  dispatch(
    actionFactory?.create(HandleError, {
      hasError: true,
      type: ErrorHandlerType.POPUP,
    })
  );

export const routes: Route[] = [
  {
    name: RouteName.HOME,
    config: {
      path: '/',
      thunk: async (dispatch, getState) => {
        const state = getState();
        const filters = { ...state.offers.filters, ...state.location.query };
        const validatedFilters = validateFilters(filters);

        if (state.offers.list.length && state.location.kind !== 'pop') {
          return;
        }
        dispatch(
          actionFactory?.create(OffersFilters, { filters: validatedFilters, pushToUrl: false })
        );
        await apolloClient
          .query({
            query: useMocks ? GET_MOCK_OFFERS : GET_OFFERS,
            fetchPolicy: 'no-cache',
            variables: validatedFilters,
          })
          .then(response => {
            dispatch(actionFactory?.create(OffersCleared, {}));
            dispatch(actionFactory?.create(OffersFetched, response.data));
          })
          .catch(errorHandler(dispatch));
      },
    },
    component: Home,
  },
  {
    name: RouteName.CMS_PAGE,
    config: {
      path: '/static/:slug',
      thunk: async (dispatch, getState) => {
        const slug = getState().location.payload.slug;
        dispatch(actionFactory.create(CMSPageFetch, { slug }))
          .then((response: any) => {
            // TODO: redirect to 404 if not found, feasible after BE is done. Leaving log for now.
            console.log(response);
          })
          .catch(errorHandler(dispatch));
      },
    },
    component: CMSpage,
  },
  {
    name: RouteName.OFFER,
    config: {
      path: '/:offerId(\\d+)/:slug',
      workerRegex: new RegExp(/\/\d+\/?.*/g),
      thunk: async (dispatch, getState) => {
        const store = getState();
        const { offerId } = store.location.payload;
        if (store.offers.details.findIndex((offer: any) => offer.id === parseInt(offerId)) > -1) {
          return;
        }
        await apolloClient
          .query({
            query: GET_OFFER,
            fetchPolicy: 'no-cache',
            variables: {
              offerId: parseInt(offerId),
            },
          })
          .then(response => dispatch(actionFactory?.create(OfferFetched, response.data)))
          .catch(errorHandler(dispatch));
      },
    },
    component: Offer,
  },
  {
    name: RouteName.ABOUT,
    config: {
      path: '/onas',
    },
    component: AboutUs,
  },
  {
    name: RouteName.FAQ,
    config: {
      path: '/faq',
    },
    component: FAQ,
  },
  {
    name: RouteName.CONTACT,
    config: {
      path: '/kontakt',
    },
    component: Contact,
  },
  {
    name: RouteName.TERMS,
    config: {
      path: '/owu',
    },
    component: Terms,
  },
  {
    name: RouteName.POLICY,
    config: {
      path: '/polityka_prywatnosci',
    },
    component: Policy,
  },
  {
    name: RouteName.MAP,
    config: {
      path: '/mapa',
    },
    component: SiteMap,
  },
  {
    name: RouteName.BESTSELLERS,
    config: {
      path: '/channel/bestsellery',
      thunk: async (dispatch, getState) => {
        if (getState().dailyDeals.list.length) {
          return;
        }
        apolloClient
          .query({
            query: GET_DAILY_DEALS,
            fetchPolicy: 'no-cache',
          })
          .then(response => dispatch(actionFactory?.create(DailyDealsFetched, response.data)))
          .catch(errorHandler(dispatch));
      },
    },
    component: Bestsellers,
  },
  {
    name: RouteName.LISTING,
    config: {
      path: '/channel/Polska',
      thunk: async (dispatch, getState) => {
        if (getState().offers.list.length) {
          return;
        }
        apolloClient
          .query({
            query: useMocks ? GET_MOCK_OFFERS : GET_OFFERS,
            fetchPolicy: 'no-cache',
          })
          .then(response => dispatch(actionFactory?.create(OffersFetched, response.data)))
          .catch(errorHandler(dispatch));
      },
    },
    component: Channel,
  },
  {
    name: RouteName.SEARCH,
    config: {
      path: '/search',
      thunk: async (dispatch, getState) => {
        if (getState().offers.list.length) {
          return;
        }
        apolloClient
          .query({
            query: useMocks ? GET_MOCK_OFFERS : GET_OFFERS,
            fetchPolicy: 'no-cache',
          })
          .then(response => dispatch(actionFactory?.create(OffersFetched, response.data)))
          .catch(errorHandler(dispatch));
      },
    },
    component: Search,
  },
  {
    name: RouteName.SEARCH_RESULTS,
    config: {
      thunk: async (dispatch, getState) => {
        if (getState().offers.list.length) {
          return;
        }
        apolloClient.query({
          query: useMocks ? GET_MOCK_OFFERS : GET_OFFERS,
          fetchPolicy: 'no-cache',
        });
      },
    },
    component: SectionSearchResults,
  },
  {
    name: RouteName.BOX_OFFERS,
    config: {
      path: '/',
      thunk: async (dispatch, getState) => {
        if (getState().offers.list.length) {
          return;
        }
        apolloClient
          .query({
            query: useMocks ? GET_MOCK_OFFERS : GET_OFFERS,
            fetchPolicy: 'no-cache',
          })
          .then(response => dispatch(actionFactory?.create(OffersFetched, response.data)))
          .catch(errorHandler(dispatch));
      },
    },
    component: Search,
  },
  {
    name: RouteName.MY_TRIP,
    config: {
      path: '/trips/:orderToken',
      thunk: async (dispatch, getState) => {
        const store = getState();
        const { orderToken } = store.location.payload;
        await apolloClient
          .query({
            query: GET_TRIP,
            fetchPolicy: 'no-cache',
            variables: {
              token: orderToken,
            },
          })
          .then(response => dispatch(actionFactory?.create(TripFetched, response.data.trip)))
          .catch(errorHandler(dispatch));
      },
    },
    component: MyTrip,
  },
];
