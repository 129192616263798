import dayjs from 'dayjs';
import { OfferFiltersState, QueryFiltersState } from 'infrastructure/reducers/offer';

type StringifyPrimitives<T> = {
  [P in keyof T]: T[P] extends Record<string, any> ? T[P] : string;
};

type StringifiedQueryFilters = Partial<StringifyPrimitives<Required<QueryFiltersState>>>;

export const validateFilters: (
  filters: StringifiedQueryFilters & { page: number }
) => OfferFiltersState = filters => {
  const validatedFilters = {
    ...filters,
    adults: filters.adults !== undefined ? Number(filters.adults) : filters.adults,
    children: filters.children !== undefined ? Number(filters.children) : filters.children,
  };

  if (filters.toDepartureDate && !filters.fromDepartureDate) {
    delete validatedFilters.toDepartureDate;
  }

  if (
    filters.toDepartureDate &&
    filters.fromDepartureDate &&
    dayjs(filters.toDepartureDate).isBefore(filters.fromDepartureDate, 'day')
  ) {
    delete validatedFilters.fromDepartureDate;
    delete validatedFilters.toDepartureDate;
  }

  return validatedFilters;
};
