export enum UrgencyTypes {
  viewsLastHour = 'VIEWS_LAST_HOUR',
  viewsLast24Hours = 'VIEWS_LAST_24_HOURS',
  salesflowViewsLastHalfHour = 'SALESFLOW_VIEWS_LAST_HALF_HOUR',
  bookedToday = 'BOOKED_TODAY',
  topTenRanked = 'TOP_TEN_RANKED',
  priceDecreasedInLast24Hours = 'PRICE_DECREASED_IN_LAST_24_HOURS',
}

export const UrgencyThresholds: { [key in UrgencyTypes]?: number } = {
  [UrgencyTypes.salesflowViewsLastHalfHour]: 1,
  [UrgencyTypes.viewsLastHour]: 50,
  [UrgencyTypes.viewsLast24Hours]: 1,
};
