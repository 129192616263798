export const effectsMiddleware = (store: any) => (next: any) => async (action: any) => {
  if (action.effects) {
    await action.effects(store.dispatch);
  }
  return next({
    type: action.type,
    meta: action.meta,
    payload: action.payload,
  });
};
