import gql from 'graphql-tag';

export const GET_CMS_PAGE = gql`
  query getCMSpage($slug: String!) {
    staticPage(slug: $slug) {
      slug
      type
      title
      body
      active
    }
  }
`;
