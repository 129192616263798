import { Action } from './Action';
import { ActionType } from 'types/enums';
import { apolloClient } from 'infrastructure/apollo/client';
import { GET_CMS_PAGE } from 'infrastructure/graphql';
import { actionFactory } from 'infrastructure/services';

export class CMSPageFetched extends Action {
  readonly type: string = ActionType.CMS_PAGE_FETCHED;
}

export class CMSPageFetch extends Action {
  readonly type: string = ActionType.CMS_PAGE_LOADING;

  constructor(payload: string) {
    super(payload);
    this.payload = payload;
  }

  async effects(dispatch?: any) {
    const { slug } = this.payload;
    apolloClient
      .query({
        query: GET_CMS_PAGE,
        fetchPolicy: 'no-cache',
        variables: { slug },
      })
      .then(response => dispatch(actionFactory?.create(CMSPageFetched, response.data.staticPage)));
  }
}
