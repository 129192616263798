import gql from 'graphql-tag';

export const GET_OFFERS = gql`
  query getOfferSearch(
    $adults: Int
    $children: Int
    $page: Int
    $orderBy: SearchOrderBy
    $fromDepartureDate: Date
    $toDepartureDate: Date
  ) {
    offerSearch(
      searchParams: {
        adults: $adults
        children: $children
        fromDepartureDate: $fromDepartureDate
        toDepartureDate: $toDepartureDate
        pageNumber: $page
        orderBy: $orderBy
        pageSize: 15
        facets: { code: "", selected: [""] }
      }
    ) {
      totalResultsSize
      hits {
        offer {
          title
          shortTitle
          id
          discount
          summary
          relativeUrl
          currency
          oldPrice
          price
          image {
            url(w: 500, h: 300)
          }
          hotels {
            id
            starRating
          }
          urgency {
            viewsLastHour
            viewsLast24Hours
            salesflowViewsLastHalfHour
            bookedToday
            topTenRanked
            priceDecreasedInLast24Hours
          }
          ratings {
            value
            text
          }
          offerCard {
            category
            nights
            mainLocation
            discountPercentage
            priceTitle
            offerPrice
            priceTitlePerType
            showPriceTransparency
            priceBasedOn
            featureList {
              description
            }
            badges {
              title
              icon {
                id
                url(w: 30, h: 30)
                title
              }
              backgroundColor
              fontColor
            }
            flashDeal {
              daysLeft
              timeLeft
              flashCountdownText
            }
          }
        }
      }
    }
  }
`;
