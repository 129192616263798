import { RouteName } from 'types/enums';
import React, { memo } from 'react';
import { Button, ButtonSize } from '@travelistpl/falcon';
import { OfferItem as IOfferItem } from 'types/interfaces';
import Link from 'redux-first-router-link';
import { FormattedMessage } from 'react-intl';
import FavoriteButton from '../../FavoriteButton/FavoriteButton';
import { getUrgencies } from 'pages/Offer/components/Urgency/getUrgencies';
import { prepareStars } from 'infrastructure/utils/prepareStars';
import { Icon } from 'components';
import { isMobile } from 'infrastructure/utils/isMobile';
interface OfferItemProps {
  data: IOfferItem;
  withLikeButton: boolean;
}

const OfferItem: React.FC<OfferItemProps> = memo(
  ({
    data: {
      shortTitle,
      id,
      tag,
      image,
      price,
      currency,
      oldPrice,
      offerCard,
      relativeUrl,
      urgency,
      hotels,
      ratings,
    },
    withLikeButton,
  }) => (
    <Link
      to={{
        type: RouteName.OFFER,
        payload: { offerId: id, slug: relativeUrl.split('/')[2] },
      }}
      key={id}
      className="offer-item"
    >
      <div className="col-xs-12 col-lg-5 offer-item__image-container">
        {image && <img width="100%" src={image.url} alt={'listing'} loading="lazy" />}
        {offerCard.discountPercentage && (
          <div className="discount-tag">-{offerCard.discountPercentage}</div>
        )}
      </div>
      <div className="col-xs-12 col-lg-7 offer-item__description-container">
        <div className="description">
          {withLikeButton && <FavoriteButton offerID={id} isLoggedIn />}
          <div className="ratings">
            <div>
              <Icon name="thumbs" />
              {ratings.value} / 10
            </div>
            {ratings.value >= 7 ? <div>{ratings.text}</div> : null}
          </div>
          <div className="title">
            <h4>
              {shortTitle.replaceAll('*', '★')}{' '}
              {/* if title contains stars do not show star rating */}
              {!shortTitle.indexOf('*') && prepareStars(hotels[0].starRating)}
            </h4>
            <h5 className="text-muted">{offerCard.mainLocation}</h5>
          </div>
          <div className="urgency">
            <span>{getUrgencies(urgency)[0]}</span>
          </div>
          <div className="badges">
            {offerCard.badges &&
              offerCard.badges.map(badge => (
                <div
                  className="badges__item"
                  style={{ backgroundColor: badge.backgroundColor, color: badge.fontColor }}
                >
                  <span>{badge.title}</span>
                  {badge.icon && <img src={badge.icon.url} alt={badge.icon.title} />}
                </div>
              ))}
          </div>
          <div className="features">
            {offerCard.featureList &&
              offerCard.featureList.map((feature, i) => <span key={i}>{feature.description}</span>)}
          </div>
          <div className="prices-wrapper">
            <div className="prices-wrapper__content">
              {isMobile() ? (
                <div className="prices-item">
                  <span className="main-price">
                    od<span className="price"> {price / 100} </span>
                    {currency} <FormattedMessage id="PER_NIGHT" />{' '}
                    <FormattedMessage id="X_ADULTS" values={{ value: 2 }} />
                  </span>
                </div>
              ) : (
                <>
                  <div className="prices-item">
                    <div className="old-price text-muted">
                      <FormattedMessage id="FROM" /> <span className="price">{oldPrice / 100}</span>{' '}
                      {currency}
                    </div>{' '}
                    <div className="main-price">
                      <FormattedMessage id="FROM" /> <span className="price">{price / 100}</span>{' '}
                      {currency}
                    </div>{' '}
                  </div>
                  <div className="prices-item">
                    <FormattedMessage id="PER_NIGHT" />{' '}
                    <FormattedMessage id="X_ADULTS" values={{ value: 2 }} />
                  </div>
                </>
              )}
            </div>
            <div className="button-wrapper">
              <Button onClick={() => null} size={ButtonSize.small}>
                <FormattedMessage id="PICK" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
);

export default OfferItem;
