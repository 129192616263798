import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { isSSR } from 'infrastructure/utils/flags';

const authLink = setContext((_, { headers }) => {
  const token = !isSSR ? localStorage.getItem('token') : '';
  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : '',
    },
  };
});

const siteId = process.env.TB_SITE_ID || process.env.REACT_APP_TB_SITE_ID;
export const apolloClient = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }),
      new HttpLink({
        uri:
          (process.env.ENV || process.env.REACT_APP_ENV) !== 'development'
            ? `${process.env.TB_URL || process.env.REACT_APP_TB_URL || ''}/api/graphql/${
                siteId ? '?site_id=' + siteId : ''
              }`
            : 'http://localhost:9002/graphql',
      }),
    ])
  ),
  cache: new InMemoryCache(),
});
