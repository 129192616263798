import React, { memo } from 'react';
import { Loader } from '../..';
import { useSelector } from 'react-redux';
import { OfferList } from 'components';

export const SectionSearchResults: React.FC<{}> = memo(() => {
  const offers = useSelector((state: any) => state.offers.list);
  const isLoading = useSelector((state: any) => state.offers.listLoading);

  return (
    <div className="col-xs-12 col-lg-8">
      <Loader isLoading={isLoading} />
      <OfferList offers={offers} />
    </div>
  );
});
