import React, { useCallback, useEffect, useState } from 'react';
import { Icon } from 'components';
import clsx from 'clsx';

const FavoriteButton: React.FC<{ offerID: number; isLoggedIn: boolean }> = ({
  offerID,
  isLoggedIn = true,
}) => {
  const [liked, setLiked] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    // chceck if its liked here or in action
  }, []);

  const handleClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      // fire action to like offer then change, or depend only on data in store, tbd
      isLoggedIn && setLiked(!liked);
    },
    [liked, isLoggedIn]
  );

  return (
    <div
      className={clsx('favorite-wrapper', (liked || hovered) && 'liked')}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Icon name={liked || hovered ? 'heart' : 'heart_empty'} className="big-icon text-muted" />
    </div>
  );
};

export default FavoriteButton;
