import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { OfferList as IOfferList } from 'types/interfaces';
import { actionFactory } from 'infrastructure/services';
import { OffersFetch } from 'infrastructure/actions/OffersFetched';
import { OfferItem } from './components';
import { Loader } from '../Loader';
import { OfferFiltersState } from 'infrastructure/reducers/offer';

const OfferList: React.FC<{
  offers: IOfferList;
  listLength?: number;
  withLikeButton?: boolean;
  isInitialized: boolean;
  listCompleted: boolean;
  filters: OfferFiltersState;
  fetchOffers: (filters: OfferFiltersState) => void;
}> = memo(
  ({
    offers,
    listLength,
    withLikeButton = true,
    isInitialized,
    listCompleted,
    filters,
    fetchOffers,
  }) => {
    const mappedOffers = useMemo(() => {
      if (offers) {
        const offerList = listLength ? offers.slice(0, 3) : offers;
        return offerList.map(offerListItem => (
          <OfferItem
            key={offerListItem.offer.id}
            data={offerListItem.offer}
            withLikeButton={withLikeButton}
          />
        ));
      }
    }, [offers, listLength, withLikeButton]);

    const loadMore = (): void => {
      if (!isInitialized) {
        return;
      }
      fetchOffers({ ...filters, page: filters.page + 1 });
    };

    return (
      <InfiniteScroll
        initialLoad={false}
        pageStart={filters.page}
        loadMore={loadMore}
        hasMore={!listCompleted && isInitialized}
        loader={isInitialized ? <Loader isLoading={true} key="loader" /> : undefined}
      >
        {mappedOffers}
      </InfiniteScroll>
    );
  }
);

const mapStateToProps = (state: any) => ({
  isInitialized: !state.offers.listLoading,
  listCompleted: state.offers.listCompleted,
  filters: state.offers.filters,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchOffers: (filters: OfferFiltersState) => {
    dispatch(actionFactory.create(OffersFetch, filters));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferList);
