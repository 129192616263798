import { ActionType, RouteName } from 'types/enums';
import { Action } from './Action';

export class OffersFilters extends Action {
  readonly type: string = ActionType.OFFERS_FILTERS;

  async effects(dispatch?: any) {
    const { pushToUrl, filters } = this.payload;
    if (pushToUrl) {
      const { page, ...otherFilters } = filters;

      dispatch({
        type: RouteName.HOME,
        payload: { query: otherFilters },
      });
    }
  }
}
