import gql from 'graphql-tag';

export const GET_CURRENT_USER = gql`
  query getUser {
    currentUser {
      email
      firstName
      lastName
      phone
    }
  }
`;
