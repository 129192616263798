import { RouteObject } from 'redux-first-router';
import { Route } from 'types/interfaces';
import { Error404 } from 'pages';

export class Router {
  routes: Route[] = [];
  mappedRoutes: { [key: string]: RouteObject } = {};

  constructor(routes: Route[]) {
    this.routes = routes;
    this.mapRoutes();
  }

  mapRoutes() {
    this.routes.forEach(route => {
      this.mappedRoutes[route.name] = route.config;
    });
  }

  getComponent(routeName: string) {
    const route = this.routes.find(route => route.name === routeName);
    if (route && route.component) {
      return route.component;
    }
    return Error404;
  }
}
