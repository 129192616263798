import React from 'react';
import { createPortal } from 'react-dom';
import { ErrorContent } from './ErrorContent';

const ErrorModal: React.FC = () => {
  return createPortal(
    <div className="error-modal__overlay">
      <div className="error-modal">
        <ErrorContent></ErrorContent>
      </div>
    </div>,
    document.body
  );
};

export default ErrorModal;
