import React from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { Vulture } from '@travelistpl/vulture';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import { apolloClient } from 'infrastructure/apollo/client';
import App from 'app';
import * as serviceWorker from './serviceWorker';
import { configureStore } from 'infrastructure/configureStore';
import { ErrorBoundary, Loader } from 'components';
import 'styles/main.scss';
import 'styles/pages.scss';
import 'styles/components.scss';

declare global {
  interface Window {
    __STATE__: any;
    vt: Vulture;
  }
}

if ((process.env.ENV || process.env.REACT_APP_ENV) === 'production') {
  new Vulture({ gtm: { containerId: 'GTM-PPXFGW' } });
}

// Add Polish locale for proper date formatting
dayjs.locale('pl');

if (typeof navigator !== 'undefined') {
  const { store } = configureStore('/', window.__STATE__);

  const renderMethod = !!module.hot ? render : hydrate;
  renderMethod(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <ErrorBoundary>
            <React.Suspense fallback={<Loader isLoading={true} />}>
              <App />
            </React.Suspense>
          </ErrorBoundary>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,

    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA

  serviceWorker.unregister();
}
