import gql from 'graphql-tag';

export const GET_OFFER = gql`
  query getOffer($offerId: ID!) {
    offer(id: $offerId) {
      id
      title
      price
      currency
      oldPrice
      shortTitle
      includedList
      excludedList
      summary
      placeDescriptions {
        title
        text
      }
      infoList {
        accommodationId
        infoList {
          id
          label
          value
        }
      }
      contentBlocks(contentType: "things_to_do") {
        title
        content
        bulletedContent
      }
      image {
        id
        title
        url(w: 800, dpr: 72)
      }
      relativeUrl
      gallery {
        id
        title
        imgUrl: url(w: 1138, dpr: 72)
        thumbnailUrl: url(w: 200, dpr: 72)
      }
      offerCard {
        category
        nights
        mainLocation
        discountPercentage
        priceTitle
        offerPrice
        priceTitlePerType
        showPriceTransparency
        priceBasedOn
        flashDeal {
          daysLeft
          timeLeft
          flashCountdownText
        }
        offercardProducts {
          icon
          description
        }
      }
      urgency {
        viewsLastHour
        viewsLast24Hours
        salesflowViewsLastHalfHour
        bookedToday
        topTenRanked
        priceDecreasedInLast24Hours
      }
      hotels {
        id
        unitsName
        type
        images {
          id
          title
          url(w: 1400, dpr: 72)
        }
        description {
          title
          subtitle
          text
        }
        location {
          lat
          lon
          placeId
          description
          address
        }
        partner {
          company
          address
          city
          zipCode
          country
          vatNumber
        }
        facilities {
          icon
          nameTranslated
          name
        }
        rooms {
          id
          thumbImage {
            id
            url(w: 360, dpr: 72)
            title
          }
          description {
            title
            subtitle
            text
          }
          facilities {
            icon
            nameTranslated
            name
          }
        }
      }
      ratings {
        average
        count
      }
      similarOffers {
        id
        shortTitle
        price
        oldPrice
        relativeUrl
        currency
        excludedCharges {
          name
          amount
        }
        image {
          url(w: 500, h: 300)
        }
        offerCard {
          mainLocation
          discountPercentage
          priceTitle
          priceTitlePerType
          offercardProducts {
            icon
            description
          }
        }
      }
    }
  }
`;

// destinationText {
//   content
//   location
// }
