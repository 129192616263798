import { ActionType } from 'types/enums';

const defaultState = {
  loading: true,
  data: null,
};

export const trip = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.TRIP_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.TRIP_FETCHED:
      return {
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
