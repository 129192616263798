import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { router } from 'infrastructure/services';
import { actionFactory } from 'infrastructure/services';
import { UserFetch } from 'infrastructure/actions';
import { isSSR } from 'infrastructure/utils/flags';

interface AppProps {
  component: string;
  getUser: Function;
}

const App: React.FC<AppProps> = ({ component, getUser }) => {
  const AppComponent = router.getComponent(component);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!isSSR && token) {
      getUser();
    }
  }, []);

  return <AppComponent />;
};

const mapStateToProps = ({ component }: { component: string }) => ({
  component,
});

export default connect(mapStateToProps, (dispatch: any) => ({
  getUser: () => dispatch(actionFactory.create(UserFetch, null)),
}))(App);
