import { ActionType, ErrorHandlerType } from 'types/enums';

interface errorState {
  hasError: boolean;
  type: ErrorHandlerType;
}

const defaultState: errorState = {
  hasError: false,
  type: ErrorHandlerType.STATIC,
};

export const error = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.HANDLE_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
