import React from 'react';
import { IconsId } from 'types/interfaces/iconTypes';
import clsx from 'clsx';

interface IconProps {
  name: IconsId;
  className?: string;
}

// This component in combination with font typing enables hints when adding icons in markup

const Icon: React.FC<IconProps> = ({ name, className = '', ...props }) => (
  <i className={clsx(`icon-${name}`, className)} {...props}></i>
);

export default Icon;
