import { ActionType } from 'types/enums';

interface cmsPageState {
  loading: boolean;
  data: any;
}

const defaultState: cmsPageState = {
  loading: false,
  data: {},
};

export const cmsPage = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.CMS_PAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionType.CMS_PAGE_FETCHED:
      return {
        loading: false,
        data: {
          ...state.data,
          [action.payload.slug]: action.payload,
        },
      };
    default:
      return state;
  }
};
