import { Hotel, OfferList } from 'types/interfaces';
import { ActionType } from 'types/enums';

export type SearchOrderFieldName = 'PRICE' | 'TIMESBOOKED';

export type SearchOrderDirection = 'ASC' | 'DESC';

export interface SearchOrderBy {
  field: SearchOrderFieldName;
  order: SearchOrderDirection;
}

export interface QueryFiltersState {
  adults?: number;
  children?: number;
  orderBy?: SearchOrderBy;
  fromDepartureDate?: string;
  toDepartureDate?: string;
}

export interface OfferFiltersState extends QueryFiltersState {
  page: number;
}

interface OfferState {
  list: OfferList;
  listLoading: boolean;
  totalResultsSize: number | null;
  listCompleted: boolean;
  details: any[];
  showTagList: boolean;
  modal: { [key: string]: Hotel };
  filters?: OfferFiltersState;
}

const defaultState: OfferState = {
  list: [],
  listLoading: true,
  totalResultsSize: null,
  listCompleted: false,
  details: [],
  showTagList: false,
  modal: {},
  filters: {
    page: 1,
  },
};

export const offer = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.OFFERS_LOADING:
      return {
        ...state,
        listLoading: action.payload,
      };
    case ActionType.OFFERS_FETCHED:
      const list = [...state.list, ...action.payload.offerSearch.hits];
      return {
        ...state,
        list: list,
        listLoading: false,
        totalResultsSize: action.payload.offerSearch.totalResultsSize,
        listCompleted:
          action.payload.offerSearch.totalResultsSize === list.length ||
          action.payload.offerSearch.hits.length === 0,
      };
    case ActionType.OFFER_FETCHED:
      return {
        ...state,
        details: [...state.details, action.payload.offer],
      };
    case ActionType.FILTER_BY_TAG:
      return {
        ...state,
        showTagList: action.payload,
        list: state.list.filter((offer: any) => {
          return offer.tag === action.payload;
        }),
      };
    case ActionType.OFFERS_CLEARED:
      return {
        ...state,
        list: [],
        filters: { ...state.filters, page: 1 },
      };
    case ActionType.OFFER_MODAL_FETCHED:
      return {
        ...state,
        modal: {
          ...state.modal,
          [action.payload.offer.id]: action.payload.offer.hotels[0],
        },
      };
    case ActionType.OFFERS_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };
    default:
      return state;
  }
};
