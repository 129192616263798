export enum ActionType {
  OFFERS_FETCHED = 'OFFERS_FETCHED',
  OFFERS_FETCH = 'OFFERS_FETCH',
  OFFERS_LOADING = 'OFFERS_LOADING',
  OFFERS_FILTERS = 'OFFERS_FILTERS',
  OFFER_FETCHED = 'OFFER_FETCHED',
  OFFERS_CLEARED = 'OFFERS_CLEARED',
  FILTER_BY_TAG = 'FILTER_BY_TAG',
  SEND_EVENT = 'SEND_EVENT',
  OFFER_MODAL_FETCHED = 'OFFER_MODAL_FETCHED',
  OFFER_MODAL_LOADING = 'OFFER_MODAL_LOADING',
  HANDLE_ERROR = 'HANDLE_ERROR',
  CMS_PAGE_FETCHED = 'CMS_PAGE_FETCHED',
  CMS_PAGE_LOADING = 'CMS_PAGE_LOADING',
  TRIP_FETCHED = 'TRIP_FETCHED',
  TRIP_LOADING = 'TRIP_LOADING',
  DAILY_DEALS_FETCHED = 'DAILY_DEALS_FETCHED',
  DAILY_DEALS_LOADING = 'DAILY_DEALS_LOADING',
  SHOW_LOGIN = 'SHOW_LOGIN',
  TOKEN_FETCH = 'TOKEN_FETCH',
  TOKEN_FETCHED = 'TOKEN_FETCHED',
  USER_FETCH = 'USER_FETCH',
  USER_FETCHED = 'USER_FETCHED',
  AUTH_ERROR = 'AUTH_ERROR',
  LOGOUT = 'LOGOUT',
}
