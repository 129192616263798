import gql from 'graphql-tag';

//from graphql faker
export const GET_MOCK_OFFERS = gql`
  {
    offers(siteIds: ["46"], published: true) {
      title
      shortTitle
      id
      tag
      startDate
      endDate
      adults
      children
      destination
      relativeUrl
      currency
      oldPrice
      price
      offerFeatures
      image {
        url(w: 330, h: 216)
      }
      offerCard {
        category
        nights
        mainLocation
        discountPercentage
        priceTitle
        offerPrice
        priceTitlePerType
        showPriceTransparency
        priceBasedOn
        flashDeal {
          daysLeft
          timeLeft
          flashCountdownText
        }
        offercardProducts {
          icon
          description
        }
      }
    }
  }
`;
