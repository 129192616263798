import { ActionType } from 'types/enums';
import { User } from 'types/interfaces';

interface authState {
  loginModalShown: boolean;
  user: User | null;
  error: any;
  loading: boolean;
}

const defaultState: authState = {
  loginModalShown: false,
  user: null,
  error: null,
  loading: false,
};

export const auth = (state = defaultState, action: any = {}) => {
  switch (action.type) {
    case ActionType.SHOW_LOGIN:
      return Object.assign(
        {
          ...state,
          loginModalShown: action.payload,
        },
        !action.payload ? { error: null } : {}
      );
    case ActionType.USER_FETCHED:
      return {
        ...state,
        error: null,
        user: action.payload,
        loginModalShown: false,
        loading: false,
      };
    case ActionType.AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionType.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case ActionType.USER_FETCH:
    case ActionType.TOKEN_FETCH:
      return {
        ...state,
        loading: true,
      };
    case ActionType.TOKEN_FETCHED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
